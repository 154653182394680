import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'
import { Idiomorph } from 'idiomorph'

// Automatically AJAX load and render the resource in the element
// Works with ManualAutoLoadController to show content quickly
export default class extends Controller {
  static targets = ['content']
  static values = {
    immediate: Boolean,
    pollSec: Number
  }

  connect() {
    this.loaded = false
    window.document.addEventListener(
      `auto-load:${this.data.get('url')}`, this.manualLoad.bind(this)
    )

    if (this.immediateValue) {
      this.appear()
    } else {
      useIntersection(this)
    }
  }

  disconnect() {
    window.document.removeEventListener(`auto-load:${this.data.get('url')}`, this.manualLoad)

    if (this.pollTimeout) { clearTimeout(this.pollTimeout) }
  }

  manualLoad() {
    this.appear()
  }

  poll() {
    if (!this.hasPollSecValue) { return }

    this.pollTimeout = setTimeout(() => {
      console.log('hey')
      this.loaded = false
      this.manualLoad()
    }, this.pollSecValue * 1000)
  }

  async appear() {
    if (this.loaded == false) {
      const response = await fetch(this.data.get('url'))

      if (response.ok) {
        const html = await response.text()
        
        this.loaded = true

        if (this.hasPollSecValue) {
          Idiomorph.morph(this.contentTarget, html, {morphStyle:'innerHTML'})
        } else {
          this.contentTarget.innerHTML = html
        }
      }
    
      this.poll()
    }
  }
}
